<template>
    <div class="animate__animated animate__fadeInUp">
        <v-card-title>{{$t("support.upcoming-features.title")}}</v-card-title>

        <v-card-subtitle>
            {{$t("g.only-in-german")}}
        </v-card-subtitle>

        <v-card-text class="content-from-textarea">{{upcoming_features}}</v-card-text>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                upcoming_features: "",
            }
        },
        async beforeCreate() {
            let response           = await this.func.ax("/php/getter.php", {
                func: "get_upcoming_features",
            });
            this.upcoming_features = response.upcoming_features;
        },
        metaInfo() {
            return {
                title: `${this.$t('meta.page-title-unformated')} - ${this.$t('meta.page-title-upcoming-features')}`,
                meta : [{
                    name   : "description",
                    content: this.$t("meta.description"),
                }]
            }
        },
    }
</script>